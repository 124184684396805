import React from 'react';
import { Box, Typography } from '@material-ui/core';

import '../style/page.scss';
import Page from '../components/page';

const LearnMore = (props: any) => {
  return (
    <Page isFetching={false} container={true}>
      <Box py={4}>
        <Typography variant="body1" align="center">
          A user guide for the new ORS will be on this page in the near future
        </Typography>
      </Box>
    </Page>
  );
};

export default LearnMore;
